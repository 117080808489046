<template>
  <div class="flex flex-col gap-2">
    <div>
      <b-button
        variant="primary"
        size="sm"
        @click="addGroup()"
        :disabled="loadingCount > 0"
      >
        Add new group
      </b-button>
    </div>

    <table class="table table-google user-groups">
      <thead>
        <tr>
          <th class="text-nowrap">ID</th>
          <th class="text-nowrap">Group</th>
          <th class="text-nowrap row-fit">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="group in groups" :key="group.Id">
          <td class="row-fit text-right">{{ leftPadZero(group.Id, idTargetLength) }}</td>
          <td>{{ group.Name }}</td>
          <td>
            <div class="flex gap-2">
              <b-button
                variant="primary"
                size="sm"
                @click="editGroup(group)"
                :disabled="loadingCount > 0"
              >
                Edit
              </b-button>

              <b-button
                variant="danger"
                size="sm"
                @click="deleteGroup(group)"
                :disabled="loadingCount > 0 || group.Id === 0"
              >
                Delete
              </b-button>
            </div>
          </td>
        </tr>

        <tr v-if="groups.length === 0">
          <td colspan="4">
            <span v-if="loadingCount > 0">Loading..</span>
            <span v-else>No groups found.</span>
          </td>
        </tr>
      </tbody>
    </table>

    <user-group-modal
      v-if="groupModal.showModal"
      :data="groupModal"
      @saved="fetchGroups()"
    />
  </div>
</template>

<script>
import {
  getArrayTargetLength,
  leftPadZero,
} from '@/helper';

const UserGroupModal = () => import('@/components/admin/UserGroupModal.vue');

export default {
  name: 'UserGroups',
  components: {
    UserGroupModal,
  },
  computed: {
    idTargetLength() {
      return getArrayTargetLength(this.groups.map((group) => group.Id));
    },
  },
  data() {
    return {
      loadingCount: 0,
      groups: [],
      groupModal: {
        showModal: false,
        groupid: null,
      },
    };
  },
  methods: {
    fetchGroups() {
      this.loadingCount++;
      this.$http
        .post('/rpc')
        .send({ api: 'user' })
        .send({ method: 'groups' })
        .then((res) => {
          this.groups = res.body;
        })
        .catch((err) => {
          this.$toast.error(err.response.text);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    addGroup() {
      this.groupModal.groupid = null;
      this.groupModal.showModal = true;
    },
    editGroup(group) {
      this.groupModal.groupid = group.Id;
      this.groupModal.showModal = true;
    },
    renameGroup(group) {
      const groupName = prompt('Enter new group name', group.Name);
      if (!groupName) {
        return;
      }
      const params = {
        name: groupName,
        groupid: group.Id,
      };
      this.loadingCount++;
      this.$http
        .post('/rpc')
        .send({ api: 'user' })
        .send({ method: 'group' })
        .send({ params })
        .then(() => {
          this.fetchGroups();
        })
        .catch((err) => {
          this.$toast.error(err.response.text);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    deleteGroup(group) {
      const confirmed = confirm(`Do you really wish to delete group '${group.Name}'?`);
      if (!confirmed) {
        return;
      }
      const params = {
        groupid: group.Id,
      };
      this.loadingCount++;
      this.$http
        .post('/rpc')
        .send({ api: 'user' })
        .send({ method: 'groupDel' })
        .send({ params })
        .then(() => {
          this.fetchGroups();
        })
        .catch((err) => {
          this.$toast.error(err.response.text);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    leftPadZero(value, targetLength) {
      return leftPadZero(value, targetLength);
    },
  },
  created() {
    this.fetchGroups();
  },
};
</script>

<style lang="scss" scoped>
  .user-groups {
    max-width: 500px;
  }
</style>
